<template>
  <app-view-container id="user-info" class="my-4">
    <form v-if="currentDialog" @submit.prevent="onSubmit">
      <v-card-title class="text-h3 semi-bold px-6 mt-4">
        <v-row align="center">
          <v-col v-if="currentDialog.isAlert" cols="auto">
            <icon-alert class="user-info__alert-icon" />
          </v-col>
          <v-col v-if="currentDialog.title" cols="auto">
            <h3 class="semi-bold" v-html="currentDialog.title"></h3>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="user-info__content body-1 px-6">
        <p v-if="currentDialog.subtitle" class="mt-4 semi-bold" v-html="currentDialog.subtitle"></p>
        <component
          :is="{
            template: `<p class='mt-4'>${currentDialog.text}</p>`
          }"
        />

        <app-input-row no-indent class="mt-4">
          <app-checkbox v-model="hideNextTime" :label="$t('dontShowAnymore')" />
        </app-input-row>
      </v-card-text>

      <v-spacer />

      <v-divider />

      <v-card-actions class="px-6">
        <v-row>
          <v-col cols="12">
            <app-btn type="submit" block title="navigation.ok" />
          </v-col>
        </v-row>
      </v-card-actions>
    </form>
  </app-view-container>
</template>

<script>
import RedirectMixin from '@/mixins/RedirectMixin';
import UserInfoMixin from '@/mixins/UserInfoMixin';
import { NAMESPACE } from '@/modules/Authentication/store';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'UserInfoDialog',

  mixins: [RedirectMixin, UserInfoMixin],

  data: () => ({
    shownDialogs: [],
    currentDialog: null,
    hideNextTime: false
  }),

  computed: {
    ...mapState(NAMESPACE, ['storedInfoDialogs'])
  },

  methods: {
    ...mapMutations(NAMESPACE, {
      setStoredInfoDialogs: 'SET_STORED_INFO_DIALOGS'
    }),
    onSubmit() {
      if (this.hideNextTime) {
        this.setStoredInfoDialogs([...this.storedInfoDialogs, this.currentDialog.key]);
        this.hideNextTime = false;
      }
      this.shownDialogs.push(this.currentDialog.key);
      this.getNextDialog();
      if (!this.currentDialog) {
        this.redirectToLastRoute();
      }
    },

    getNextDialog() {
      this.currentInfoDialogIndex = this.activeInfoDialogs.findIndex(
        (dialog) =>
          !this.storedInfoDialogs.includes(dialog.key) && !this.shownDialogs.includes(dialog.key)
      );
      this.currentDialog = this.activeInfoDialogs[this.currentInfoDialogIndex];

      if (this.currentDialog) {
        this.$router.push({ params: { key: this.currentDialog.key } });
      }
    }
  },

  created() {
    if (this.$route.params.key) {
      this.currentDialog = this.infoDialogs.find((dialog) => dialog.key === this.$route.params.key);

      if (this.currentDialog && this.hasFulfilledCondition(this.currentDialog)) {
        return;
      }
    }

    this.getNextDialog();

    if (!this.currentDialog) {
      this.redirectToLastRoute();
    }
  }
};
</script>

<style scoped lang="scss">
.user-info {
  &__alert-icon {
    height: 80px;
    width: 80px;
    color: var(--c-error);
    opacity: 0.7;
  }

  &__content {
    white-space: pre-line;
  }
}
</style>
